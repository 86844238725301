import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, LoaderFunction, redirect } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import isLogin from '../../utils/isLogin';

const Register = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState<null | { error: string, type: "email" | "password" | "username" | 'first-name' | "last-name" | null, }>(null);
    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (submit) return;
        setSubmit(true)
        let returnUrl = "/";
        const searchParams = new URLSearchParams(window.location.search);
        const planId = searchParams.get("planId");
        if (planId) {
            returnUrl = `/plans/${planId}`
        }

        if (password !== confirmPassword) {
            setErrorMessage({ error: "password and confirm password", type: "password" });
        } else {
            const loginUrl = new URL('/register', config.api_server_uri);
            axios.post(loginUrl.href, {
                firstName,
                lastName,
                password,
                email
            }).then((result) => {
                const json = result.data;
                if (json.code === 201) {
                    if (json.token) {
                        localStorage.setItem('token', json.token);
                        navigate(returnUrl)
                    } else setErrorMessage({ error: "error to get token", type: null });

                } else {
                    setErrorMessage({
                        error: json.message,
                        type: json.errorMode[0],
                    });
                }
                setSubmit(false)
            }).catch((err) => {
                setErrorMessage({
                    error: "error to join serveur",
                    type: null,
                });
                setSubmit(false)
            });
        }
    };
    useEffect(() => {

    }, [navigate]);
    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">

                <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src=".\img\icon.png" alt="logo" draggable="false" />
                    digital mural
                </div>
                {
                    errorMessage ? <div className="bg-red-100 border border-red-400 text-red-700 px-10 py-3 rounded relative mb-8" role="alert">
                        <span className="block sm:inline">{errorMessage.error}</span>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                        </span>
                    </div> : null
                }
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Create and account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
                            <div>
                                <label
                                    htmlFor="first-name"
                                    className={(errorMessage && errorMessage.type === "first-name") ?
                                        "block mb-2 text-sm font-medium text-gray-900 dark:text-red-500" :
                                        "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    }>
                                    Your first name
                                </label>
                                <input
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    type="text"
                                    name="fist name"
                                    id="username"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="username"
                                    required={true} />
                            </div>
                            <div>
                                <label
                                    htmlFor="last-name"
                                    className={(errorMessage && errorMessage.type === "last-name") ?
                                        "block mb-2 text-sm font-medium text-gray-900 dark:text-red-500" :
                                        "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    }>
                                    Your last name
                                </label>
                                <input
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                    type="text"
                                    name="username"
                                    id="username"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="username"
                                    required={true} />
                            </div>
                            <div>
                                <label htmlFor="email" className={(errorMessage && errorMessage.type === "email") ?
                                    "block mb-2 text-sm font-medium text-gray-900 dark:text-red-500" :
                                    "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                }>Your email</label>
                                <input value={email} onChange={(event) => setEmail(event.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required={true} />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className={(errorMessage && errorMessage.type === "password") ?
                                        "block mb-2 text-sm font-medium text-gray-900 dark:text-red-500" :
                                        "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    }>Password</label>
                                <input value={password} onChange={(event) => setPassword(event.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={true} />
                            </div>
                            <div>
                                <label htmlFor="confirm-password" className={(errorMessage && errorMessage.type === "password") ?
                                    "block mb-2 text-sm font-medium text-gray-900 dark:text-red-500" :
                                    "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                }>Confirm password</label>
                                <input value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={true} />
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                {submit ? <div className='w-full flex items-center justify-center'>
                                    <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </div> : <>register</>}

                            </button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Already have an account? <NavLink to={`/login${window.location.search}`} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</NavLink>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Register;
export const loader: LoaderFunction = async (data) => {
    const isLog = await isLogin();
    if (isLog) return redirect('/dashboard')
    else return null;
};
